<template>
  <div>
    <slot name="header" />
    <div class="container mx-auto pb-8">
      <div class="mt-8 max-w-2xl mx-auto">
        <form @submit.prevent="handleContinue">
          <input-field
            type="email"
            name="email"
            v-model="formData.email"
            label="Epost"
            class="mb-4" />
        </form>
      </div>
    </div>
    <div class="container mx-auto px-4 pb-12">
      <div class="max-w-2xl mx-auto">
        <input-checkbox
          v-model="formData.newsletter"
          :label="'Jeg ønsker å motta nyhetsbrev fra ' + $app.name"
          class="mb-4"
          label-class="font-bold" />
        <p
          class="text-sm text-muted"
          v-if="false">
          En tekst om nyhetsbrevet her?
        </p>
      </div>
    </div>
    <slot :is-valid="isValid" />
  </div>
</template>

<script>
import validate from "validate.js"
import EventBus from "@/utils/event-bus"

export default {
  props: {
    data: {
      type: Object,
      default () {
        return {}
      }
    },

    formData: {
      type: Object,
      default () {
        return {}
      }
    }
  },

  methods: {
    handleContinue () {
      if (this.isValid) {
        EventBus.$emit("emailChange", this.formData.email)
        this.$emit("continue")
      }
    }
  },

  computed: {
    modelErrors () {
      return validate.single(this.formData.email, {
        presence: true,
        email: true
      })
    },

    isValid () {
      return (typeof this.modelErrors === "undefined")
    }
  }
}
</script>
